@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-Light.ttf') format('truetype');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-LightItalic.ttf')
		format('truetype');
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-Regular.ttf')
		format('truetype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-Italic.ttf') format('truetype');
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-Medium.ttf') format('truetype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-MediumItalic.ttf')
		format('truetype');
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-SemiBold.ttf')
		format('truetype');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-SemiBoldItalic.ttf')
		format('truetype');
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: url('../../assets/fonts/CormorantGaramond-BoldItalic.ttf')
		format('truetype');
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: 'Cormorant SC';
	src: url('../../assets/fonts/CormorantSC-Light.ttf') format('truetype');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Cormorant SC';
	src: url('../../assets/fonts/CormorantSC-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Cormorant SC';
	src: url('../../assets/fonts/CormorantSC-Medium.ttf') format('truetype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Cormorant SC';
	src: url('../../assets/fonts/CormorantSC-SemiBold.ttf') format('truetype');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'Cormorant SC';
	src: url('../../assets/fonts/CormorantSC-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: 700;
}
