@use './variables' as *;
@use './mixins' as *;

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
}

html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
	font-family: $font-title;
	margin: 0;
	color: $primary-text-color;
	background-color: $primary-background-color;
}

p {
	font-family: $font-stack;
	color: $secondary-text-color;
}

a {
	text-decoration: none;
	color: $primary-text-color;
}