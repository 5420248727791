//Font
$font-title: "Cormorant SC";
$font-stack: "Cormorant Garamond";

//primary colours
$primary-text-color: #966B28;
$primary-background-color: #262626;
$primary-hover-color: #E6C068;

//secondary colours
$secondary-text-color: #ebebeb; 
$secondary-background-color: #DDDDDD;
$secondary-hover-color: #F1EAAE;
$title-color: #9f843d;
$stack-color: #343434;

//Responsive breakpoints
$tablet-breakpoint: 768px;
$laptop-breakpoint: 1024px;
$desktop-breakpoint: 1280px;
