@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.hero {
	@include layout-margin(2.4rem);

	@include tablet {
		@include layout-margin(9rem);
	}

	@include desktop {
		margin-bottom: 10rem;
	}

	&__container {
		@include flex(column);
		align-items: center;
		padding-top: 15rem;
		gap: 5rem;

		@include desktop {
			width: 110rem;
			margin: 0 auto;
			padding-top: 12rem;
			gap: 10rem;
		}
	}

	&__logo-text-image-container {
		@include flex(column);
		align-items: center;

		@include desktop {
			@include flex();
			gap: 10rem;
			align-items: flex-start;
		}
	}

	&__info-container {
		@include flex(column);
		gap: 5rem;
		align-items: center;

		@include desktop {
			align-items: flex-start;
			gap: 0rem;
		}
	}

	&__logo {
		width: 90vw;
		height: 90vw;

		@include tablet {
			width: 50vw;
			height: 50vw;
		}

		@include desktop {
			width: 45rem;
			height: 45rem;
		}
	}

	&__text-image-container {
		@include flex(column);
		align-items: center;
		gap: 5rem;

		@include desktop {
			gap: 0rem;
		}
	}

	&__text-container {
		text-align: center;
		@include tablet {
			@include flex(column);
		}
	}

	&__title {
		font-size: 3.6rem;
		@include headlines-margin();

		@include tablet {
			font-size: 4.8rem;
		}

		@include desktop {
			@include headlines-margin(1rem, 0);
			text-align: start;
			font-size: 4.2rem;
		}
	}

	&__text {
		@include components-margin();
		font-size: 2rem;

		@include tablet {
			font-size: 2.4rem;
		}

		@include desktop {
			@include components-margin(1.6rem, 2.4rem);
			text-align: start;
			font-size: 2rem;
		}
	}

	&__image-container {
		width: 31rem;
		height: 31rem;
		border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
		background-image: linear-gradient(
			to bottom right,
			#f8f8ff,
			#f8d26b,
			#966b28,
			#7a5821
		);
		background-size: 100% 100%;
		-webkit-animation: liquidmotion 8s ease-in-out infinite;
		animation: liquidmotion 8s ease-in-out infinite;

		@include tablet {
			width: 41rem;
			height: 41rem;
		}

		@include desktop {
			width: 36rem;
			height: 36rem;
		}
	}

	&__image {
		position: relative;
		top: 0.5rem;
		left: 0.5rem;
		width: 30rem;
		height: 30rem;
		border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
		-webkit-animation: liquidmotion 8s ease-in-out infinite;
		animation: liquidmotion 8s ease-in-out infinite;
		transition: all 1s ease-in-out;

		@include tablet {
			width: 40rem;
			height: 40rem;
		}

		@include desktop {
			width: 35rem;
			height: 35rem;
		}
	}

	@-webkit-keyframes liquidmotion {
		0% {
			border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
		}

		50% {
			border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
		}

		to {
			border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
		}
	}

	@keyframes liquidmotion {
		0% {
			border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
		}

		50% {
			border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
		}

		to {
			border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
		}
	}

	&__link-container {
		@include flex();
		align-items: center;
		justify-content: center;
		gap: 3rem;

		@include desktop {
			margin-top: -4rem;
			margin-left: 17.5rem;
			gap: 2rem;
		}
	}

	&__link {
		transition: all 0.5s;
		font-size: 6rem;

		@include tablet {
			font-size: 7rem;
		}

		@include desktop {
			font-size: 5rem;
			text-align: center;
		}

		&:hover {
			color: $secondary-text-color;
		}

		&:active {
			font-size: 5rem;

			@include tablet {
				font-size: 6rem;
			}

			@include desktop {
				font-size: 4rem;
			}
		}
	}

	&__tech-stack-container {
		@include flex(column);
		align-items: center;
		gap: 2rem;

		@include desktop {
			@include flex();
			gap: 2rem;
			align-items: center;
		}
	}

	&__tech-stack {
		font-size: 2.4rem;
		border-bottom: 2px solid $primary-text-color;
		padding-bottom: 1rem;
		margin-bottom: 3rem;

		@include desktop {
			align-self: flex-start;
			border-right: 2px solid $primary-text-color;
			border-bottom: 0;
			padding-bottom: 0;
			padding-right: 2rem;
			width: 32rem;
			margin-top: 10rem;
		}
	}

	&__tech-stack-images {
		@include reset-list;
		@include flex();
		justify-content: center;
		flex-wrap: wrap;
		gap: 2rem;

		@include tablet {
			gap: 2.5rem;
		}
	}

	&__tech-stack-item {
		@include flex();
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		background-color: $primary-background-color;
		border-radius: 50%;
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);

		@include tablet {
		}

		& img {
			height: auto;
			width: 4.6rem;

			@include tablet {
			}
		}
	}
}
