@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.project-card {
	@include flex(column);
	justify-content: space-between;
	gap: 3rem;
	height: 75rem;
	width: 100%;
	background-color: $primary-background-color;
	border-radius: 1.7rem;
	box-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
	padding: 2rem;
	font-size: 1.8rem;

	@include tablet {
		gap: 2rem;
	}

	@include desktop {
		@include flex();
		height: 40rem;
		gap: 8rem;
	}

	&__image-container {
		width: 100%;
		height: auto;
		background-color: hsla(0, 0%, 46%, 0.2);
		border-radius: 1.7rem;
		box-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
		overflow: hidden;
	}

	&__image {
		aspect-ratio: 16 / 12;
		height: auto;
		width: 100%;
		// transition: transform 10s ease-in-out 0s;

		// &:hover {
		// 	transform: translateY(-74%);

		// 	@include desktop {
		// 		transform: translateY(-60%);
		// 	}
		// }
	}

	&__info-container {
		@include flex(column);
		justify-content: center;
		gap: 2rem;
		width: 100%;
		height: auto;
		text-align: center;

		@include tablet {
			gap: 1rem;
		}
	}

	&__title {
		@include desktop {
			margin-bottom: 1rem;
		}
	}

	&__text {
	}

	&__stack-container {
		@include flex();
		flex-wrap: wrap;
		gap: 1.2rem;
		justify-content: center;

		@include tablet {
			margin-top: 2rem;
		}

		@include desktop {
			margin-top: 3rem;
			gap: 1.6rem;
		}
	}

	&__stack {
		box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.6);
		padding: 1rem;
		background-color: $stack-color;
	}

	&__link-container {
		@include flex();
		gap: 1rem;
		justify-content: center;
		align-items: center;

		@include tablet {
			margin-top: 2rem;
		}

		@include desktop {
			margin-top: 4rem;
			gap: 2.5rem;
		}

		& a {
			@include flex();
			gap: 0.5rem;
			align-items: center;

			& i {
				font-size: 2.2rem;
			}
		}
	}

	&__link {
	}
}

.reverse {
	@include desktop {
		flex-direction: row-reverse;
	}
}
